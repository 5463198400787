import { createRouter, createWebHistory } from 'vue-router';
const routes = [
  { path: '/', component: () => import("../views/web.vue") },
  { path: '/web', component: () => import("../views/web.vue") },
  { path: '/mobile', component: () => import("../views/mobile.vue") },
  { path: '/guide', component: () => import("../views/guide.vue") }
]

const router = createRouter({
  history: createWebHistory(),
  routes: routes
});

export default router;