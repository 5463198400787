<template>
  <router-view />
</template>

<script setup>
import "./style/main.css";
import { onMounted } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
onMounted(() => {
  if (window.location.pathname == "/guide") {
    return;
  }

  if (_isMobile()) {
    router.push("/mobile");
  } else {
    router.push("/web");
  }
});
const _isMobile = () => {
  let flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );
  return flag;
};
</script>
